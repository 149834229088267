import {
  Box,
  Stack,
  FormControlLabel,
} from "@mui/material";
import CustomButton from "src/components/main/CustomButton";
import CustomPaper from "src/components/main/CustomPaper";
import CustomText from "src/components/main/CustomText";
import { getCurrentTime } from "src/utils/formatter";
import { useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import { UpdateItemLog } from "src/api/item";
import CustomDropbox from "../CustomDropbox";
import ModalConfirm from "../ModalConfirm";
import CustomFormGroup from "../CustomFormGroup";
import CustomCheckbox from "../CustomCheckbox";
import { useLoadingStore } from "src/store/loadingStore";
import { ShowLoading } from "../Loading";

interface Props {
  detailItem: DetailItem;
  refetch: () => void;
}
export default function MaterialCollection({ detailItem, refetch }: Props) {
  const [baseFiles, setBaseFiles] = useState<any>([]);
  const [leatherFiles, setLeatherFiles] = useState<any>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [data, setData] = useState<any>({
    base: Boolean(detailItem?.ItemImage),
    leather: Boolean(detailItem?.LeatherImage),
  });

  const id = useLocation().pathname.split("/").pop();
  const { showNotification } = useLoadingStore();

  const handleFileUploadBase = useCallback((acceptedFiles: File[]) => {
    setBaseFiles((prevFiles: any) => [...prevFiles, ...acceptedFiles]);
  }, []);

  const handleDeleteBase = (fileName: string) => {
    setBaseFiles((prevFiles: any[]) =>
      prevFiles.filter((file: { name: string }) => file.name !== fileName)
    );
  };

  const handleFileUploadLeather = useCallback((acceptedFiles: File[]) => {
    setLeatherFiles((prevFiles: any) => [...prevFiles, ...acceptedFiles]);
  }, []);

  const handleDeleteLeather = (fileName: string) => {
    setLeatherFiles((prevFiles: any[]) =>
      prevFiles.filter((file: { name: string }) => file.name !== fileName)
    );
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    if (name === "base" || name === "leather") {
      setData((prevData: any) => ({
        ...prevData,
        [name]: event.target.checked,
      }));
    } else {
      setData((prevData: any) => ({ ...prevData, [name]: value }));
    }
  };

  const handleSubmit = async () => {
    const req: CompleteStep = {
      item_id: id!,
      status: "COMPLETED",
      status_desc: "All Material has been Collected",
      attachment: [],
      notes: "All Material has been Collected",
      enum_status: "COMPLETED",
    };

    await UpdateItemLog(req);
    await refetch();
    setOpenModal(false);
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    if (!detailItem?.ItemImage || !detailItem?.LeatherImage) {
      showNotification("All Material Should Be Ready to Complete Step", "error");
      return;
    }
    setOpenModal(true); // Open the modal on form submit
  };

  return (
    <Box component={"form"} onSubmit={handleFormSubmit} sx={{ minWidth: "50%", width: "fit-content" }}>
      <CustomPaper style={{ padding: 20, marginBottom: 20, width: "100%" }}>
        <Stack
          direction={"row"}
          display={"flex"}
          justifyContent={"space-between"}
        >
          <CustomText variant="body1" fontWeight={"bold"}>
            Material Collection
          </CustomText>
          <CustomText variant="body2">{getCurrentTime()}</CustomText>
        </Stack>
        <Stack mt={2} spacing={2}>
          <Stack>
            <CustomText variant="body1">Material Checklist</CustomText>
            <CustomFormGroup>
              <Stack direction={"row"}>
                <FormControlLabel
                  control={
                    <CustomCheckbox checked={Boolean(detailItem?.ItemImage)} />
                  }
                  label="Base"
                  id="base"
                  name="base"
                />
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      checked={Boolean(detailItem?.LeatherImage)}
                    />
                  }
                  label="Leather"
                  id="leather"
                  name="leather"
                />
              </Stack>
            </CustomFormGroup>
          </Stack>
        </Stack>
        {data?.base && (
          <Stack mt={2}>
            <CustomText variant="body1">Base Material Proof</CustomText>
            <Box
              mt={1}
              display={"flex"}
              justifyContent={"flex-start"}
              flexDirection={"row"}
            >
              <CustomDropbox
                size={100}
                viewOnly={true}
                existingFiles={detailItem?.ItemImage}
                onUpload={handleFileUploadBase}
                onDelete={handleDeleteBase}
              />
            </Box>
          </Stack>
        )}
        {data?.leather && (
          <Stack mt={2}>
            <CustomText variant="body1">Leather Material Proof</CustomText>
            <Box
              mt={1}
              display={"flex"}
              justifyContent={"flex-start"}
              flexDirection={"row"}
            >
              <CustomDropbox
                size={100}
                viewOnly={true}
                existingFiles={detailItem?.LeatherImage}
                onUpload={handleFileUploadLeather}
                onDelete={handleDeleteLeather}
              />
            </Box>
          </Stack>
        )}

        <Box mt={2} display={"flex"} justifyContent={"flex-end"}>
          <CustomButton variant="contained" type="submit">
            Submit
          </CustomButton>
        </Box>
      </CustomPaper>
      <ModalConfirm
        text="Are you sure you want to submit this data?"
        open={openModal}
        onClose={() => setOpenModal(false)}
        onSubmit={handleSubmit}
      />
    </Box>
  );
}
