import { Grid, Paper, Stack } from "@mui/material";
import { useState } from "react";
import { DoApproveRejectOffer } from "src/api/item";
import CustomButton from "src/components/main/CustomButton";
import CustomText from "src/components/main/CustomText";
import ModalConfirm from "src/components/main/ModalConfirm";
import useThemeStore from "src/store/themeStore";
import { DecimalFormatter } from "src/utils/formatter";

interface Props {
  data?: any;
  starting_price: number;
  refetch: () => void;
}
export default function Offer({ data, starting_price, refetch }: Props) {
  const { theme } = useThemeStore();
  const [openApprove, setOpenApprove] = useState<boolean>(false);
  const [openReject, setOpenReject] = useState<boolean>(false);

  const handleApprove = async () => {
    await ApproveRejectOffer("Approved");
    setOpenApprove(false);
  };

  const handleReject = async () => {
    await ApproveRejectOffer("Rejected");
    setOpenReject(false);
  };

  const ApproveRejectOffer = async (status: string) => {
    const req = {
      id: data?.id,
      item_id: data?.item_id,
      status: status,
      status_desc: status === "Approved" ? "Offer Accepted" : "Offer Rejected",
    };
    await DoApproveRejectOffer(req);
    await refetch();
  };

  return (
    <Paper
      style={{ padding: 20, marginBottom: 20 }}
      sx={{
        background: theme?.inputPrimary,
      }}
    >
      <Grid
        container
        spacing={2}
        direction={"row"}
        justifyContent="space-between"
        alignItems="start"
      >
        <Grid item sm={7} md={9}>
          <Stack
            direction="row"
            spacing={{ sm: 2, md: 5, lg: 10 }}
            display={"flex"}
            justifyContent={"flex-start"}
          >
            <Stack direction="column" spacing={2}>
              <CustomText variant="body1" gutterBottom>
                Supplier Name
              </CustomText>
              <CustomText variant="body1" gutterBottom fontWeight={700}>
                {data?.supplier_name}
              </CustomText>
            </Stack>
            <Stack direction="column" spacing={2}>
              <CustomText variant="body1" gutterBottom>
                Offering Price
              </CustomText>
              <CustomText variant="body1" gutterBottom fontWeight={700}>
                Rp {DecimalFormatter(data?.offering_price.toString())}
              </CustomText>
            </Stack>
            <Stack direction="column" spacing={2}>
              <CustomText variant="body1" gutterBottom>
                Starting Price
              </CustomText>
              <CustomText variant="body1" gutterBottom fontWeight={700}>
                Rp {DecimalFormatter(starting_price?.toString())}
              </CustomText>
            </Stack>
          </Stack>
        </Grid>
        <Grid item sm={5} md={3}>
          <Stack
            mt={2}
            direction={{ xs: "column", md: "row" }}
            display={"flex"}
            justifyContent={"flex-end"}
            spacing={2}
            gap={2}
          >
            {data?.status === "Rejected" ? (
              <CustomButton variant="contained" disabled>
                Rejected
              </CustomButton>
            ) : data?.status === "Approved" ? (
              <CustomButton variant="contained" disabled>
                Confirmed
              </CustomButton>
            ) : (
              <>
                <CustomButton
                  variant="contained"
                  onClick={() => setOpenReject(true)}
                >
                  Reject
                </CustomButton>
                <CustomButton
                  variant="contained"
                  onClick={() => setOpenApprove(true)}
                >
                  Accept
                </CustomButton>
              </>
            )}
          </Stack>
        </Grid>
      </Grid>
      <ModalConfirm
        text="Are you sure you want to approve this offer?"
        open={openApprove}
        onClose={() => setOpenApprove(false)}
        onSubmit={handleApprove}
      />
      <ModalConfirm
        text="Are you sure you want to reject this offer?"
        open={openReject}
        onClose={() => setOpenReject(false)}
        onSubmit={handleReject}
      />
    </Paper>
  );
}
