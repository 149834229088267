import { Box, Grid, InputAdornment, MenuItem, Stack } from "@mui/material";
import CustomButton from "src/components/main/CustomButton";
import CustomInput from "src/components/main/CustomInput";
import CustomText from "src/components/main/CustomText";
import NewInputNominal from "src/components/main/NewInputNominal";
import { currencies, currenciesShipment, getCurrencySymbol } from "src/utils/paramData";
import CustomCard from "src/components/main/CustomCard";
import UserAutocomplete from "src/components/main/CustomAutocomplete/UserAutocomplete";
import CustomDropbox from "src/components/main/CustomDropbox";
import ImageHolder from "src/components/main/CustomDropbox/ImageHolder";
import React, { useState, useEffect } from "react";
import { getItemLogAttachmentGroup } from "src/api/item";

interface Props {
  disabled?: boolean;
  data: PurchaseItem | RequestAddPurchaseItem;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  onUpload: (files: File[]) => void;
  onDelete: (index: number) => void;
}

export default function PurchasingForm({
  disabled = false,
  data,
  onChange,
  onSubmit,
  onUpload,
  onDelete,
}: Props) {
  const [attachment, setAttachment] = useState<Attachment[]>([]);

  useEffect(() => {
    if (data?.type !== "RequestAddPurchaseItem" && data?.attachment) {
      fetchAttachment(data?.attachment);
    }
  }, [data]);

  const fetchAttachment = async (id: string) => {
    const response = await getItemLogAttachmentGroup(id);
    setAttachment(response?.data);
  };

  return (
    <Box component={"form"} onSubmit={onSubmit}>
      <CustomCard text="Purchasing Data">
        <Grid container columnSpacing={4}>
          <Grid item sm={4}>
            <Stack my={"10px"}>
              <CustomText variant="body1" required={!disabled}>
                Item Name
              </CustomText>
              <CustomInput
                onChange={onChange}
                disabled={disabled}
                size="small"
                id="item_id"
                name="item_id"
                value={data?.item_id || ""}
                required
              />
            </Stack>
            <Stack my={"10px"}>
              <CustomText variant="body1" required={!disabled}>
                User’s Name
              </CustomText>
              {data?.type === "RequestAddPurchaseItem" ? (
                <UserAutocomplete />
              ) : (
                <CustomInput
                  onChange={onChange}
                  disabled={disabled}
                  size="small"
                  id="user_id"
                  name="user_id"
                  value={data?.display_name || ""}
                  required
                />
              )}
            </Stack>
          </Grid>
          <Grid item sm={4}>
            <Stack my={"10px"}>
              <CustomText variant="body1" required={!disabled}>
                Currency
              </CustomText>
              <CustomInput
                required
                disabled={true}
                onChange={onChange}
                size="small"
                id="currency"
                name="currency"
                select
                defaultValue={
                  data?.currency ? data?.currency : currenciesShipment[0].value
                }>
                {currencies.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </CustomInput>
            </Stack>
          </Grid>
          <Grid item sm={4}>
            <Stack my={"10px"}>
              <CustomText variant="body1" required={!disabled}>
                Buying Price
              </CustomText>
              <NewInputNominal
                required
                disabled={disabled}
                size="small"
                id="cost"
                name="cost"
                value={data?.cost?.toString() || ""}
                onChange={onChange}
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {getCurrencySymbol(data?.currency)}
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
          </Grid>
        </Grid>
        <Stack my={"10px"}>
          <CustomText variant="body1">Notes</CustomText>
          <CustomInput
            onChange={onChange}
            disabled={disabled}
            size="small"
            id="notes"
            name="notes"
            multiline
            rows={4}
            value={data?.notes || ""}
          />
        </Stack>
        <Stack mt={2}>
          <CustomText variant="body1" gutterBottom required={!disabled}>
            Attachment
          </CustomText>
        </Stack>

        <Box
          display={"flex"}
          justifyContent={"flex-start"}
          gap={2}
          flexDirection={"row"}
          maxHeight={"15%"}>
          {data?.type === "RequestAddPurchaseItem" && (
            <CustomDropbox
              files={data?.attach}
              filesType={"base64"}
              onUpload={onUpload}
              deleteByIndex={true}
              onDeleteByIndex={onDelete}
            />
          )}
          {attachment && <ImageHolder image={attachment} size={100} />}
        </Box>
      </CustomCard>
      {!disabled && (
        <Box
          mt={2}
          display={"flex"}
          justifyContent={"flex-end"}
          gap={2}
          flexDirection={"row"}>
          <CustomButton variant="contained" type="submit">
            Submit
          </CustomButton>
        </Box>
      )}
    </Box>
  );
}
