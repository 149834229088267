import { Box, Collapse, Link, Stack } from "@mui/material";
import { useLocation } from "react-router-dom";
import CustomButton from "src/components/main/CustomButton";
import CustomPaper from "src/components/main/CustomPaper";
import CustomText from "src/components/main/CustomText";
import CustomInput from "src/components/main/CustomInput";
import useThemeStore from "src/store/themeStore";
import { HideLoading, ShowLoading } from "../Loading";
import { getWAMessage, getWAMessageDesignQC, UpdateItemLog } from "src/api/item";
import NotificationAlert from "../Alert";
import { getCurrentTime } from "src/utils/formatter";
import { SetStateAction, useEffect, useState } from "react";
import { useLoadingStore } from "src/store/loadingStore";
import { formatPhoneNumber } from "src/utils/utils";
import InputDate from "../Datepicker";
import ModalConfirm from "../ModalConfirm";
import TaskPoint from "../TaskPoint";
import { approveTask, reviseTask } from "src/api/task";
import { priorityLevel, taskType } from "src/utils/paramData";
import ImageHolder from "../CustomDropbox/ImageHolder";

type DesignType = "READY" | "CUSTOM";
interface Props {
  designType: DesignType;
  taskId?: string;
  refetch: () => void;
}
export default function ClientApproveDesign({
  designType,
  taskId,
  refetch,
}: Props) {
  const { theme } = useThemeStore();
  const { showNotification } = useLoadingStore();
  const [data, setData] = useState<any>({});
  const [message, setMessage] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [isRevision, setIsRevision] = useState<boolean>(false);
  const [openModalApprove, setOpenModalApprove] = useState<boolean>(false);
  const [openModalRevision, setOpenModalRevision] = useState<boolean>(false);
  const [it, setIt] = useState<ItemDesignQC | null>(null)
  const [itExpand, setItExpand] = useState(false)
  const [messageDesign, setMessageDesign] = useState<string>("");
  const [phoneNumberDesign, setPhoneNumberDesign] = useState<string>("");

  const id = useLocation().pathname.split("/").pop();

  const fetchWAMessage = async () => {
    const res = await getWAMessage(id!);
    setIt(res?.data as ItemDesignQC)
    setMessage(res?.data?.wa_message || "");
    setPhoneNumber(res?.data?.no_telp || "");
  };

  const getWAMessageDesignQCService = async () => {
    const res = await getWAMessageDesignQC(id!);
    setIt(res?.data as ItemDesignQC)
    setMessage(res?.data?.wa_message || "");
    setPhoneNumber(res?.data?.no_telp || "");
  };

  useEffect(() => {
    if(designType === "READY") {
      fetchWAMessage();
    }else {
      getWAMessageDesignQCService();

    }
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setData((prevData: any) => ({ ...prevData, [name]: value }));
  };

  const handleApprove = async () => {
    const req: TaskApproval = {
      task_id: taskId!,
      is_completed: true,
    };
    await approveTask(req);
    await refetch();
    setOpenModalApprove(false);
  };

  const handleComplete = async () => {
    const req: CompleteStep = {
      item_id: id!,
      status: "COMPLETED",
      status_desc: "Client Approved Design",
      attachment: [],
      notes: data?.notes,
      enum_status: "COMPLETED",
    };

    await UpdateItemLog(req);
    await refetch();
    setOpenModalApprove(false);
  };

  const handleRevision = async () => {
    const req: TaskRevision = {
      task_id: taskId,
      main_day: parseInt(data?.main_day),
      type_task: "DESIGN",
      ...data,
    };
    await reviseTask(req);
    await refetch();
    setData({});
    setIsRevision(false);
  };

  const handleFormSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    setOpenModalApprove(true);
  };

  const handleCopyClipboard = () => {
    navigator.clipboard
      .writeText(decodeURIComponent(message?.replace(/\+/g, " ")))
      .then(() => {
        showNotification("Message copied to clipboard", "success");
      });
  };

  useEffect(() => {
    const calculateTotalDays = () => {
      if (data?.end_date && data?.start_date) {
        const totalDays =
          Math.floor(
            (new Date(data.end_date).getTime() -
              new Date(data.start_date).getTime()) /
              (1000 * 60 * 60 * 24)
          ) + 1;
        setData((prevData: any) => ({
          ...prevData,
          main_day: totalDays,
        }));
      }
    };

    calculateTotalDays();
  }, [data?.end_date, data?.start_date]);
  
  return (
    <Box component={"form"} sx={{ width: "50%" }}>
      <CustomPaper style={{ padding: 20, marginBottom: 20, width: "100%" }}>
        <Stack
          direction={"row"}
          display={"flex"}
          justifyContent={"space-between"}>
          <Stack 
          direction={"row"}
          display={"flex"}
          gap={1}>
            <CustomText variant="body1" fontWeight={"bold"}>
              Client Approve Design {taskId ? `(${taskId})` : ""}
            </CustomText>
            <Link
              component="button"
              variant="body2"
              color={theme?.textLink}
              onClick={(e) => {
                e.preventDefault()
                setItExpand(prev => !prev)
              }}>
              (View Design)
            </Link>
          </Stack>
          <CustomText variant="body2">{getCurrentTime()}</CustomText>
        </Stack>
        <Collapse
          in={itExpand}
          unmountOnExit={false}>
            <ImageHolder image={it?.attachment || []} withDownload/>
        </Collapse>
        <Box mt={2}>
          <CustomText variant="body1">Send a Message</CustomText>
          <Stack
            sx={{
              flexDirection: { xs: "column", md: "row" },
              gap: { xs: 1, md: 2 },
            }}
            mt={2}
            display={"flex"}
            justifyContent={"flex-start"}
            alignItems={"center"}>
            <CustomButton
              variant="contained"
              onClick={() =>
                window.open(
                  `https://wa.me/${formatPhoneNumber(
                    phoneNumber
                  )}?text=${message}`,
                  "_blank"
                )
              }>
              WA.me Link
            </CustomButton>
            <CustomText variant="body1">or</CustomText>
            <CustomButton
              variant="contained"
              onClick={() => handleCopyClipboard()}>
              Copy to clipboard
            </CustomButton>
          </Stack>
        </Box>
        {isRevision && (
          <Stack width={"100%"} mt={2}>
            <Stack direction={"row"} gap={2}>
              <Stack>
                <CustomText variant="body1">Start Date</CustomText>
                <InputDate
                  id="start_date"
                  name="start_date"
                  onChange={handleChange}
                  value={data?.start_date}
                />
              </Stack>
              <Stack>
                <CustomText variant="body1">End Date</CustomText>
                <InputDate
                  id="end_date"
                  name="end_date"
                  onChange={handleChange}
                  value={data?.end_date}
                />
              </Stack>
              <Stack>
                <CustomText variant="body1">Man Days</CustomText>
                <CustomInput
                  required
                  disabled
                  size="small"
                  id="main_day"
                  name="main_day"
                  onChange={handleChange}
                  value={data?.main_day}
                />
              </Stack>
            </Stack>
          </Stack>
        )}
        <Stack mt={2}>
          <CustomText variant="body1">Notes</CustomText>
          <CustomInput
            size="small"
            id="notes"
            name="notes"
            onChange={handleChange}
            value={data?.notes}
          />
        </Stack>
        <Box mt={2} display={"flex"} justifyContent={"flex-end"} gap={2}>
          {isRevision ? (
            <>
              <CustomButton
                variant="outlined"
                customType="cancel"
                onClick={() => setIsRevision(false)}>
                Cancel
              </CustomButton>
              <CustomButton
                variant="contained"
                disabled={data?.main_day < 1}
                onClick={() => setOpenModalRevision(true)}>
                Submit Revision
              </CustomButton>
            </>
          ) : (
            <>
              {designType === "CUSTOM" && (
                <CustomButton
                  variant="contained"
                  onClick={() => setIsRevision(true)}>
                  Ask For Revision
                </CustomButton>
              )}

              <CustomButton
                variant="contained"
                onClick={() => setOpenModalApprove(true)}>
                Approve
              </CustomButton>
            </>
          )}
        </Box>
      </CustomPaper>
      <ModalConfirm
        text="Are you sure want to ask for revision on this task?"
        open={openModalRevision}
        onClose={() => setOpenModalRevision(false)}
        onSubmit={handleRevision}
      />
      <ModalConfirm
        text="Are you sure you want to complete this step?"
        open={openModalApprove}
        onClose={() => setOpenModalApprove(false)}
        onSubmit={designType === "CUSTOM" ? handleApprove : handleComplete}
      />
    </Box>
  );
}
