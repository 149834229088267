import { Box, Card, Grid, ToggleButtonGroup } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../../components/main/CustomTable";
import CustomText from "src/components/main/CustomText";
import Filter from "./components/Filter";
import PageTitle from "src/components/main/CustomText/PageTitle";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import IosShareIcon from "@mui/icons-material/IosShare";
import CustomButton from "src/components/main/CustomButton";
import useThemeStore from "src/store/themeStore";
import { useEffect, useState } from "react";
import ActionDisbursement from "./components/ActionDisbursement";
import { FINANCE_INCENTIVE_COLUMNS } from "src/constants/finance";
import { downloadReportIncentive, getFinanceIncentiveList } from "src/api/finance";
import { DecimalFormatter } from "src/utils/formatter";
import CustomToggle from "src/components/main/CustomToggle";
import { useFinanceStore } from "src/store/financeStore";
import { useLoadingStore } from "src/store/loadingStore";
import { useFilterStore } from "src/store/filterStore";

type TMode = "all" | "pending";

export default function FinanceInsentive() {
  const navigate = useNavigate();
  const { theme } = useThemeStore();

  const [mode, setMode] = useState<TMode>("all");
  const [data, setData] = useState<FinanceIncentiveList[]>([]);
  const [checkedData, setCheckedData] = useState<any>([]);
  const [openDisbursment, setOpenDisbursment] = useState<boolean>(false);
  const [disburseList, setDisburseList] = useState<any>([]);

  const [filter, setFilter] = useState<FilterTable>({
    status: [],
    key: "",
    order_key: "",
    sort_type: "",
    start_date: "",
    end_date: "",
    page: 1,
    limit: 10,
    total_page: 1,
    order_date_start: "",
    order_date_end: "",
    periode: "",
    periode_year: "",
  });


  const { status, resetFilterStore } = useFilterStore();


  const downloadReportIncentiveFunction = async () => {
    await downloadReportIncentive({
      limit: filter.limit,
      page: filter.page,
      periode: filter.periode,
      periode_year: filter.periode_year,
      status: filter.status

    });
  };

  const { setIdGroup } = useFinanceStore();

  const handleFilterChange = (name: string, value: any) => {
    if (name !== "page" && name !== "total_page") {
      setFilter((prevFilter) => ({
        ...prevFilter,
        ["page"]: 1,
      }));
    }
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };


  const fetchData = async () => {
    const response = await getFinanceIncentiveList({
      limit: filter.limit,
      page: filter.page,
      periode: filter.periode,
      periode_year: filter.periode_year,
      status: filter.status

    });

    response?.data?.data ? setData(formatData(response?.data?.data)) : setData([]);
    handleFilterChange("total_page", response?.data?.total_page);
    response?.data?.total_page < filter.page && handleFilterChange("page", 1);
  };


  useEffect(() => {
    fetchData();
  }, [
    filter.status,
    filter.page,
    filter.limit,
    filter.periode,
    filter.periode_year,
  ]);


  const formatData = (data: FinanceIncentiveList[]) => {

    if (!data) return []; // Return empty array if data is null or undefined

    const formattedData = data.map((item: FinanceIncentiveList) => ({
      ...item,
      id: `${item.year_month}-${item.user_id}|${item.id_grouping}`,
      disburse_date: item.disburse_date?.split("T")[0] || '', // Fallback to empty string if disburse_date is invalid
      cost: `Rp ${DecimalFormatter(item.total_cost?.toString() || '0')}`, // Ensure cost is a valid number
    }));
    return formattedData;
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    if (checked) {
      setDisburseList(data?.map((item: any) => item.id));
    } else {
      setDisburseList([]);
    }
  };

  useEffect(() => {
    // Apply filter status from selected item in dashboard
    handleFilterChange("status", [...status]);
    return () => {
      resetFilterStore();
    };
  }, []);

  const handleClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    event.stopPropagation();
    const { checked } = event.target;

    if (checked) {
      setDisburseList((prev: any) => [...prev, id]);
    } else {
      setDisburseList((prev: any) =>
        prev.filter((item: string) => item !== id)
      );
    }
  };
  const { showNotification } = useLoadingStore.getState();


  const handleAction = () => {
    const checkedData = data?.filter(
      (item: any) => disburseList.includes(item.id) && item.status === "Pending"
    );

    if (checkedData?.length < 1 || !checkedData) {
      showNotification("Please Select at Least 1 Item", "error");
    } else {
      setCheckedData(checkedData);
      setOpenDisbursment(true);
    }

  };

  const onClickDetail = (id: string) => {
    setIdGroup(id.split("|")[1]);
    navigate(`/finance/incentive/${id.split("|")[0]}`);
  };

  const handleChangeMode = (
    event: React.MouseEvent<HTMLElement>,
    mode: TMode
  ) => {
    if (mode === null) {
      return;
    }
    setMode(mode);
    if (mode === "pending") {
      handleFilterChange("status", ["Pending"]);
    } else {
      handleFilterChange("status", []);
    }
    setDisburseList([]);
  };

  const onClose = () => {
    setOpenDisbursment(false);
    fetchData();
  };

  return (
    <Box>
      <PageTitle>Monthly Incentive</PageTitle>
      <Grid container columnSpacing={2}>
        <Grid item sm={2}>
          <Filter
            mode={mode}
            filter={filter}
            onChangeFilter={handleFilterChange}
          />
        </Grid>
        <Grid item sm={10}>
          <Card>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              borderBottom={`1px solid ${theme?.border}`}
              alignContent={"center"}
              p={2}
            >
              <Box sx={{ display: "inline-block", alignContent: "center" }}>
                <CustomText variant="subtitle1" fontWeight={700} align="center">
                  Monthly Incentive List
                </CustomText>
              </Box>
              <Box
                display={"flex"}
                gap={2}
                justifyContent={"space-between"}
                alignContent={"center"}
              >
                <Box
                  display={"flex"}
                  gap={2}
                  justifyContent={"space-between"}
                  alignContent={"center"}
                >
                </Box>
                <CustomButton onClick={downloadReportIncentiveFunction} variant="contained" startIcon={<IosShareIcon />}>
                  Export to Excel
                </CustomButton>
              </Box>
            </Box>
            <CustomTable
              columns={FINANCE_INCENTIVE_COLUMNS}
              data={data}
              onClickDetail={onClickDetail}
              checkbox={mode !== "all"}
              selectedItem={disburseList}
              rowCount={data?.length}
              onSelectAll={handleSelectAll}
              onCheckboxChange={handleClick}
              identifier="id"
              filter={filter}
              onChangeFilter={handleFilterChange}
            />
          </Card>
        </Grid>
      </Grid>
      <ActionDisbursement
        isOpen={openDisbursment}
        onClose={onClose}
        data={checkedData}
        refetch={fetchData}
      />
    </Box>
  );
}
