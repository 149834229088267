export const getCurrencySymbol = (currencyValue: string) => {
  const currency = currencies.find(
    (currency) => currency.value === currencyValue
  );
  return currency ? currency.symbol : "";
};

export const Ekspedisi = [
  {
    value: "JNE",
    label: "JNE",
  },
  {
    value: "JNT",
    label: "JNT",
  },
  {
    value: "Antaraja",
    label: "Antaraja",
  },
  {
    value: "SiCepat",
    label: "SiCepat",
  },
];

export const userRole = [
  {
    value: "9faa490e-4ee3-44ad-a6d7-05560c9e7efb",
    label: "Admin",
  },
  {
    value: "2e8675f7-8663-456b-af59-7fb1a1e5d872",
    label: "Worker",
  },
  {
    value: "be8e03e0-efd6-4c83-bfb9-94c55fbf2060",
    label: "Logistic",
  },
];

export const userStatus = [
  {
    value: "Active",
    label: "Active",
  },
  {
    value: "Inactive",
    label: "Inactive",
  },
];

export const priorityLevel = [
  {
    value: "Low",
    label: "Low",
  },
  {
    value: "Medium",
    label: "Medium",
  },
  {
    value: "High",
    label: "High",
  }
];

export const userLevel = [
  {
    value: "Junior",
    label: "Junior",
  },
  {
    value: "Middle",
    label: "Middle",
  },
  {
    value: "Senior",
    label: "Senior",
  },
];

export const currencies = [
  {
    value: "IDR",
    symbol: "Rp",
  },
  {
    value: "USD",
    symbol: "$",
  },
];

export const currenciesShipment = [
  {
    value: "IDR",
    symbol: "Rp",
  }
];

export const orderFrom = [
  {
    value: "Instagram",
    label: "Instagram",
  },
  {
    value: "Whatsapp",
    label: "Whatsapp",
  },
  {
    value: "Website",
    label: "Website",
  },
  {
    value: "Walk In",
    label: "Walk In",
  },
];

export const paymentMethod = [
  {
    value: "Cash",
    label: "Cash",
    currency: "IDR", 
  },
  {
    value: "Transfer BCA",
    label: "Transfer BCA",
    currency: "IDR", 
  },
  {
    value: "PayPal",
    label: "PayPal",
    currency: "USD", 
  },
  {
    value: "Crypto",
    label: "Crypto",
    currency: "USD", 
  },
];

export const jaketSize = ["S", "M", "L", "XL", "XXL"];

export const dompetSize = ["Short", "Long"]

export const shoesSize = [
  "6", "6.5", "7", "7.5", "8", "8.5", "9", "9.5", "10", "10.5", "11", "11.5", "12", "12.5", "13",
  "13.5", "14", "14.5", "15", "15.5", "16", "16.5", "17", "17.5", "18", "18.5", "19", "19.5", "20", "20.5",
  "21", "21.5", "22", "22.5", "23", "23.5", "24", "24.5", "25", "25.5", "26", "26.5"
];

export const itemType = [
  {
    value: "Shoes",
    label: "Shoes",
    size: shoesSize,
  },
  {
    value: "Jacket",
    label: "Jacket",
    size: jaketSize,
  },
  {
    value: "Wallet",
    label: "Wallet",
    size: dompetSize,
  },
  {
    value: "Bag",
    label: "Bag",
    defaultSize:true
  },
]

export const kidsShoeSizes = [
  // { label: "0C - infants", value: "0C" },
  // { label: "1C - infants", value: "1C" },
  { label: "2C - toddlers", value: "2C" },
  { label: "3C - toddlers", value: "3C" },
  { label: "4C - toddlers", value: "4C" },
  { label: "5C - toddlers", value: "5C" },
  { label: "6C - toddlers", value: "6C" },
  { label: "7C - toddlers", value: "7C" },
  { label: "8C - toddlers", value: "8C" },
  { label: "9C - toddlers", value: "9C" },
  { label: "10C - toddlers", value: "10C" },
  { label: "10.5C - preschoolers", value: "10.5C" },
  { label: "11C - preschoolers", value: "11C" },
  { label: "12C - preschoolers", value: "12C" },
  { label: "13C - preschoolers", value: "13C" },
  { label: "1Y - school-aged children", value: "1Y" },
  { label: "2Y - school-aged children", value: "2Y" },
  { label: "3Y - school-aged children", value: "3Y" },
  { label: "4Y - school-aged children", value: "4Y" },
  { label: "5Y - school-aged children", value: "5Y" },
  { label: "6Y - school-aged children", value: "6Y" },
  { label: "7Y - school-aged children", value: "7Y" }
];

export const personType = [
  {
    value: "Kids",
    label: "Kids",
    size: kidsShoeSizes,
  },
  {
    value: "Mens",
    label: "Mens",
    defaultSize: true
  },
  {
    value: "Womens",
    label: "Womens",
  },
]

export const serviceType = [
  {
    value: "d381a022-61b9-4af4-b97c-14135ebdd4f2",
    label: "Painting",
  },
  {
    value: "d381a022-61b9-4af4-b97c-14135ebdd4f3",
    label: "Decon Recon",
  },
  {
    value: "d381a022-61b9-4af4-b97c-14135ebdd4f4",
    label: "Mix Order",
  },
];

export const designType = [
  {
    value: "0707630e-b06a-4a35-8729-34305d1e8f1b",
    label: "Ready Design",
  },
  {
    value: "0707630e-b06a-4a35-8729-34305d1e8f1a",
    label: "Photoshop",
  },
];

export const taskType = [
  {
    value: "d381a022-61b9-4af4-b97c-14135ebdd4f2",
    label: "Painting",
  },
  {
    value: "d381a022-61b9-4af4-b97c-14135ebdd4f3",
    label: "Decon Recon",
  },
  {
    value: "d381a022-61b9-4af4-b97c-14135ebdd4f4",
    label: "Design",
  },
];

export const filterTable = [
  {
    page: "order",
    filter: [
      {
        name: "Order ID",
        key: "orderId",
      },
    ],
  },
  {
    page: "item",
    filter: [
      {
        name: "Order ID",
        key: "orderId",
      },
      {
        name: "Item ID",
        key: "itemId",
      },
      {
        name: "Supplier",
        key: "supplier",
      },
    ],
  },
  {
    page: "task",
    filter: [
      {
        name: "Assignee",
        key: "assignee",
      },
      {
        name: "Deadline",
        key: "deadline",
      },
    ],
  },
];

export const taskPoint = [
  {
    value: "f2913fb0-a535-436d-9e31-e5a05f44afc1",
    label: "Bronze",
  },
  {
    value: "479c885b-ff4c-4852-85fd-80c807e8aa3c",
    label: "Silver",
  },
  {
    value: "f2913fb0-a535-436d-9e31-e5a05f44afc2",
    label: "Silver (Detailing)",
  },
  {
    value: "f2913fb0-a535-436d-9e31-e5a05f44afc3",
    label: "Gold",
  },
  {
    value: "f2913fb0-a535-436d-9e31-e5a05f44afc4",
    label: "Gold (Detailing)",
  },
  {
    value: "f2913fb0-a535-436d-9e31-e5a05g44afc1",
    label: "Diamond",
  },
  {
    value: "f2913fb0-a535-436a-9e31-e5a05f44afc1",
    label: "Diamond (Detailing)",
  },
 
];
