import { Box, IconButton, ImageListItem, ImageListItemBar, Modal } from "@mui/material";
import { useState } from "react";
import DownloadOutlined from '@mui/icons-material/DownloadOutlined';
import { downloadFileFromUrl } from "src/utils/utils";

interface Props {
  image: Attachment[];
  size?: number;
  withDownload?: boolean;
}

export default function ImageHolder({ image, size = 200, withDownload = false }: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const [item, setItem] = useState<string>("");
  return (
    <Box display={"flex"} justifyContent={"flex-start"} flexDirection={"row"}>
      {image?.length > 0 &&
        image?.map((item: any, index: number) => (
          <ImageListItem
            key={index}
            sx={{
              marginRight: 2,
              width: size,
              height: size,
              borderRadius: 2,
              overflow: "hidden",
            }}>
              <img
                className="hover:cursor-pointer"
                onClick={() => {
                  setItem(item?.url_foto);
                  setOpen(true);
                }}
                src={
                  item?.url_thumb_nail ||
                  "https://i0.wp.com/sunrisedaycamp.org/wp-content/uploads/2020/10/placeholder.png?ssl=1"
                }
                loading="lazy"
              />
              {withDownload && <ImageListItemBar
                sx={{
                  background:
                    'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                    'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                }}
                title={item.title}
                position="top"
                actionIcon={
                  <IconButton
                    onClick={() => {
                      downloadFileFromUrl(item?.url_foto)
                    }}
                    sx={{ color: 'white' }}
                    aria-label={`star ${item.title}`}
                  >
                    <DownloadOutlined />
                  </IconButton>
                }
                actionPosition="left"
              />}
          </ImageListItem>
        ))}
      <Modal open={open} onClose={() => setOpen(false)}>
        <img
          src={
            item ||
            "https://i0.wp.com/sunrisedaycamp.org/wp-content/uploads/2020/10/placeholder.png?ssl=1"
          }
          onClick={() => setOpen(false)}
          loading="lazy"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            height: "90%",
            objectFit: "contain",
          }}
        />
      </Modal>
    </Box>
  );
}
